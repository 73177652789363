:root {
 --accent: #2a71c2;  
}

@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nokora:wght@400;700&display=swap');

div, aside, section {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5 {
  margin-top: 0;
}

a {
  text-decoration: none;
}

body {
  color: #2c2c2c;
  font-family: 'Nokora', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  color: white;
  display: grid;
  grid-template-columns: 300px 1fr;
  min-height: 100vh;
}

.options-bar {
  width: 100%;
  padding: 0 20px;
}

.posts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  gap: 18px;
}

.option.border {
  border-bottom: 1px solid #ffffff2b;
  padding-bottom: 10px;
}

.credits {
  padding: 20px 10px;
  text-align: center;
  font-size: 0.9em;
}

.credits .about-btn {
  color: #2c2c2c;
  background-color: #fff;
  padding: 10px 30px;
  border-radius: 10px;
  transition: all .25s;
}

.credits .about-btn:hover {
  background: #e5e5e5;
}

.about {
  text-align: center;
}

.mantine-Checkbox-label {
  cursor: pointer;
}

.col-left {
  position: sticky;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 15px;
}

.col-right {
  background: #e0ecee;
  padding: 30px 50px 50px 50px;
}

.col-header {
  color: #2c2c2c;
  text-align: center;
  margin-bottom: 21px;
  border-bottom: 1px solid #d2d2d2;
}

.col-header h1 {
  margin-bottom: 5px;
  font-size: 1.4em;
}

.col-header h2 {
  margin-bottom: 0;
  text-transform: uppercase;
  opacity: 0.6;
  line-height: 0.7;
  letter-spacing: 2px;
  font-size: 0.9em;
  color: var(--accent);
}

.post-item {
  background: #fff;
  box-shadow: 0 0 16px #00000017;
  color: #363636;
  max-width: 310px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}

.post-floating-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.post-content-wrapper {
  padding: 20px;
}

.options-bar .option {
  margin-bottom: 10px;
}

.post-thumbnail-wrapper {
  height: 130px;
  overflow: hidden;
}

.post-thumbnail-wrapper img {
  object-fit: cover;
  width: 100%;
}

.post-item .post-title {
  margin: 0 0 5px 0;
  font-size: 1.3em;
}

.post-item .post-date {
  color: #a7a7a7;
  font-weight: bold;
  font-size: 13px;
}

.post-excerpt {
  margin-bottom: 0;
}

.post-footer {
  text-align: right;
  padding: 10px 20px;
}

.post-author {
  font-style: italic;
  font-size: 15px;
  color: var(--accent);
  margin-top: 5px;  
}

.post-footer .post-readmore {
  font-size: 14px;
  color: var(--accent);
  text-decoration: none;
  font-weight: 500;
}

.generate-btn {
  margin-top: 15px;
  width: 100%;
}

#popup-root .popup-overlay {
  background: rgba(0,0,0,0.7);
}

/* Popup styling */
.popup-header {
  font-size: 1.3em;
  text-align: center;
}

.popup-header h4 {
  margin-bottom: 10px;
}

/* HIDE FIRST PHP TAG */
.code-wrapper code > .token.delimiter.important:first-child {
  display: none;
}


.code-wrapper {
  position: relative;
}

.code-wrapper .copybtn {
  position: absolute;
  right: 25px;
  top: 15px;
}

.shortcode-code {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.shortcode-code > span {
  margin-right: 5px;
  border: 1px solid #ebebeb;
  background: #f4f5f7;
  padding: 10px 15px;
  border-radius: 5px;
}

.shortcode-code .copybtn {
  height: 39px;
}

.code-wrapper code,
.code-wrapper span {
  font-size: 1em;
  border-radius: 8px;
}

.code-wrapper code {
  border: 1px solid #ebebeb;
  background: #f4f5f7;
  max-height: 49vh !important;
  overflow-y: scroll;
}

.copybtn {
  padding: 0 8px;
}

.copybtn .mantine-Button-icon {
  margin: 0;
}

p.tip {
  color: dimgrey;
  font-size: 0.9em;
}

p.tip em {
  color: #677cd0;
}

#popup-root .shortcode-modal-content {
  width: 60%;
  height: 80vh;
}

#popup-root .about-modal-content {
  width: 30%;
}

#popup-root .popup-content {
  background: #fff;
  border-radius: 15px;
  padding: 25px 30px;
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.popup-content {
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}